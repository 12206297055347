import { useState, useEffect, useContext, useRef } from "react";
import ReceivedMessageCard from "../../../components/ui/cards/ReceivedMessageCard";
import SentMessageCard from "../../../components/ui/cards/SentMessageCard";
import { AppContext } from "../../../AppContext";

import notify from "../../../utils/notify";

// api
import Chats from "../../../utils/apiServices/chats";

function Messages({ conversationId, participantId, participantType }) {
  const [value, setValue] = useState("");
  const { messages, setMessages, user } = useContext(AppContext);

  const box = useRef(null);

  const sendMessage = (e) => {
    if (e.key === "Enter") {
      setValue("");
      setMessages((prevMessages) => ({
        ...prevMessages,
        [conversationId]: [
          ...prevMessages[conversationId],
          {
            id: prevMessages[conversationId].length
              ? prevMessages[conversationId][
                  prevMessages[conversationId].length - 1
                ].id + 1
              : 0,
            body: value,
            sender: { id: user.id },
            status: "loading",
          },
        ],
      }));

      Chats.sendMessage({
        conversationId,
        participantId,
        participantType,
        message: value,
      })
        .then(() => {})
        .catch(() => {
          notify("error", "connection error");
        });
    }
  };

  useEffect(() => {
    if (box.current) {
      box.current.scrollTop = box.current.scrollHeight;
    }
  }, [messages, conversationId]);

  return (
    <div>
      {
        <div ref={box} className="conversations">
          <p className="conversation-start">
            This is the beginning of the conversation
          </p>
          {messages[conversationId]?.map((message, i) =>
            message.sender.id === user.id ? (
              <SentMessageCard
                key={message.id}
                text={message.body}
                initial={
                  message.sender.id !==
                  messages[conversationId][i - 1]?.sender.id
                }
                loading={message.status === "loading"}
                timestamp={message.created_at}
              />
            ) : (
              <ReceivedMessageCard
                key={message.id}
                text={message.body}
                initial={
                  message.sender.id !==
                  messages[conversationId][i - 1]?.sender.id
                }
                name={message.sender.name}
                pic={message.sender.profile_photo_url}
                timestamp={message.created_at}
              />
            )
          )}
        </div>
      }
      <div className="message-box">
        <input
          placeholder="Type a message..."
          type="text"
          onKeyDown={sendMessage}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </div>
  );
}

export default Messages;
