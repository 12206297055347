/* eslint-disable class-methods-use-this */

import baseURL from "./baseUrl";
import { getAdminAccessToken, getAccessToken } from "../accessToken";

class Consultants {
  async createConsultant(data) {
    const accessToken = getAdminAccessToken();

    const response = await fetch(`${baseURL}/admin/create/consultant`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to create consultant` };
  }

  async getConsultants() {
    const accessToken = getAdminAccessToken();

    const response = await fetch(`${baseURL}/consultants`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to get consultants` };
  }

  async getConsultantPatients() {
    const accessToken = getAccessToken();

    const response = await fetch(`${baseURL}/consultant/patients`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    return {
      message: `${response.statusText}. unable to get consultant patients`,
    };
  }

  async getConsultantAppointments() {
    const accessToken = getAccessToken();

    const response = await fetch(`${baseURL}/consultant/appointments`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    return {
      message: `${response.statusText}. unable to get consultant appointments`,
    };
  }

  async predictFundus(data) {
    const response = await fetch("http://localhost:8000/urlpredict", {
      method: "post",
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return response.json();
    }

    return {
      message: `${response.statusText}. unable to predict fundus image`,
    };
  }
}

const instance = new Consultants();

export default instance;
