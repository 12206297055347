import { useState, useRef, useEffect, useContext } from "react";
import ReceivedMessageCard from "../../../components/ui/cards/ReceivedMessageCard";
import SentMessageCard from "../../../components/ui/cards/SentMessageCard";
import send from "../../../assets/icons/paper-plane.png";
import { AppContext } from "../../../AppContext";

import notify from "../../../utils/notify";

// api
import ApiCall from "../../../utils/apiServices/diagnosis";

function Diagnosis({ patientId }) {
  const [value, setValue] = useState("");
  const { diagnoses, setDiagnoses, user } = useContext(AppContext);

  const box = useRef(null);

  const sendMessage = () => {
    setValue("");
    setDiagnoses((prevDiagnoses) => ({
      ...prevDiagnoses,
      [patientId]: prevDiagnoses[patientId]
        ? [
            ...prevDiagnoses[patientId],
            {
              id: prevDiagnoses[patientId].length
                ? prevDiagnoses[patientId][prevDiagnoses[patientId].length - 1]
                    .id + 1
                : 0,
              diagnosis: value,
              sender: { id: user.id },
              status: "loading",
            },
          ]
        : [
            {
              id: 0,
              diagnosis: value,
              sender: { id: user.id },
              status: "loading",
            },
          ],
    }));

    ApiCall.createDiagnosis({ patientId, diagnosis: value })
      .then(() => {})
      .catch(() => {
        notify("error", "connection error");
      });
  };

  useEffect(() => {
    if (box.current) {
      box.current.scrollTop = box.current.scrollHeight;
    }
  }, [diagnoses, patientId]);

  return (
    <div>
      {
        <div className="conversations diagnosis">
          <p className="conversation-start">
            This is the beginning of the diagnosis
          </p>
          {diagnoses[patientId]?.map((message, i) =>
            user.role === "user" ? (
              <ReceivedMessageCard
                key={message.id}
                text={message.diagnosis}
                initial={diagnoses[patientId].length === 1}
              />
            ) : (
              <SentMessageCard
                key={message.id}
                text={message.diagnosis}
                initial={diagnoses[patientId].length === 1}
              />
            )
          )}
        </div>
      }
      <div className="diagnosis-box">
        <textarea
          placeholder="Type a message..."
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <img src={send} alt="" onClick={sendMessage} />
      </div>
    </div>
  );
}

export default Diagnosis;
