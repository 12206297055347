/* eslint-disable class-methods-use-this */

import baseURL from "./baseUrl";
import { getAccessToken } from "../accessToken";

class Diagnosis {
  async createDiagnosis({ patientId, message }) {
    const accessToken = getAccessToken();

    const response = await fetch(`${baseURL}/consultant/diagnoses`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ patient: patientId, message }),
    });

    if (response.ok) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to create diagnosis` };
  }

  async getPaitientDiagnosis({ patientId }) {
    const accessToken = getAccessToken();

    const response = await fetch(
      `${baseURL}/consultant/diagnoses/${patientId}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.ok) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to get diagnoses` };
  }
}

const instance = new Diagnosis();

export default instance;
