import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import useUpdateHealthCenters from "../../hooks/healthCenters";
import HcLayout from "../../components/hcLayout";
import PageLoading from "../../components/ui/loader/pageLoading";
import Button from "../../components/ui/button/Button";
import angleDown from "../../assets/icons/angleDown.png";
import "../consultantPatients/consultantPatients.css";

// api service
import HealthCenters from "../../utils/apiServices/healthCenters";
import notify from "../../utils/notify";

const UploadModal = ({ eyeToupload, closeModal, patientId }) => {
  const [image, setImage] = useState({
    url: "",
    file: "",
  });
  const [submitting, setSubmitting] = useState(false);

  const { updateHealthCenterPatients } = useUpdateHealthCenters();

  const onImageUpload = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const url = typeof reader.result === "string" ? reader.result : "";

        setImage({ url, file });
      };
    }
  };

  const onSubmit = () => {
    setSubmitting(true);
    const attrName = {
      left: "fundus_le",
      right: "fundus_re",
    };

    const examinationData = {
      patient: patientId,
      [attrName[eyeToupload]]: image.file,
    };

    const newFormData = new FormData();

    Object.keys(examinationData).forEach((key) => {
      newFormData.append(key, examinationData[key]);
    });

    HealthCenters.uploadPatientFundus({ patientId, data: newFormData })
      .then((res) => {
        setSubmitting(false);
        if (res.status) {
          notify("success", res.message);

          updateHealthCenterPatients();
          closeModal();
        } else {
          notify("error", res.message);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        notify("error", "An error occured. Check your connection");
      });
  };

  return (
    <div className="upload-modal-wrapper">
      <div className="upload-modal-background" />
      <div className="upload-modal">
        <div className="modal-top">
          <Close onClick={closeModal} />
        </div>
        <div>
          <img src={image.url} />
        </div>
        <input
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          onChange={onImageUpload}
        />
        {image.url && (
          <Button
            type="contained"
            color="#fff"
            backgroundColor="#007aff"
            text={submitting ? "uploading..." : "Upload"}
            onClick={onSubmit}
          />
        )}
      </div>
    </div>
  );
};

function HcPatientDetails() {
  const [selectedPatient, setSelectedPatient] = useState();
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(0);
  const [eyeToupload, setEyeToUpload] = useState("");
  const { healthCenterPatients } = useContext(AppContext);
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { updateHealthCenterPatients } = useUpdateHealthCenters();

  const onClickDetails = (value) => {
    setShowDetails((preValue) => {
      if (preValue === value) return 0;
      return value;
    });
  };

  useEffect(() => {
    // fetch patients
    if (healthCenterPatients.loading) {
      updateHealthCenterPatients();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const foundPatient = healthCenterPatients.data.find(
      (hp) => hp.uuid === uuid
    );

    if (foundPatient) {
      setSelectedPatient(foundPatient);
      setLoading(false);
    }

    if (!healthCenterPatients.loading && !foundPatient) {
      notify("error", "patient not found");
      navigate("/health-center/patients");
    }

    // eslint-disable-next-line
  }, [healthCenterPatients, uuid]);

  return (
    <HcLayout>
      {loading ? (
        <PageLoading />
      ) : (
        <div className="patient-details">
          {eyeToupload && (
            <UploadModal
              eyeToupload={eyeToupload}
              closeModal={() => setEyeToUpload("")}
              patientId={uuid}
            />
          )}
          <div className={`details-group ${showDetails === 1 ? "show" : ""}`}>
            <h4
              onClick={() => {
                onClickDetails(1);
              }}
            >
              Patient Details <img src={angleDown} alt="" />
            </h4>
            <div className="details-content">
              <div className="details-row">
                <p className="label">Full Name:</p>
                <p className="value">{selectedPatient.name}</p>
              </div>
              <div className="details-row">
                <p className="label">Age:</p>
                <p className="value">
                  {Math.floor(
                    (new Date() - new Date(selectedPatient.dob)) / 31536000000
                  )}
                  &nbsp; years
                </p>
              </div>
              <div className="details-row">
                <p className="label">Gender:</p>
                <p className="value">{selectedPatient.gender}</p>
              </div>
              <div className="details-row">
                <p className="label">Phone no:</p>
                <p className="value">{selectedPatient.phone}</p>
              </div>
              <div className="details-row">
                <p className="label">Weight:</p>
                <p className="value">{selectedPatient.weight}</p>
              </div>
              <div className="details-row">
                <p className="label">Height:</p>
                <p className="value">{selectedPatient.height}</p>
              </div>
              <div className="details-row">
                <p className="label">IOP:</p>
                <p className="value">{selectedPatient.iop}</p>
              </div>
              <div className="details-row">
                <p className="label">BP:</p>
                <p className="value">{selectedPatient.bp}</p>
              </div>
              <div className="details-row">
                <p className="label">Consultant:</p>
                <p className="value">{selectedPatient.doctor_name}</p>
              </div>
            </div>
          </div>
          <div className={`details-group ${showDetails === 2 ? "show" : ""}`}>
            <h4
              onClick={() => {
                onClickDetails(2);
              }}
            >
              Fundus Images <img src={angleDown} alt="" />
            </h4>
            <div className="details-content">
              <div className="details-images">
                <div className="details-image">
                  <p>Left Eye:</p>
                  <img src={selectedPatient.image?.fundus_le_url} alt="" />
                  <Button
                    type="contained"
                    color="#fff"
                    backgroundColor="#007aff"
                    text="Upload LE"
                    onClick={() => setEyeToUpload("left")}
                  />
                </div>
                <div className="details-image">
                  <p>Right Eye:</p>
                  <img src={selectedPatient.image?.fundus_re_url} alt="" />
                  <Button
                    type="contained"
                    color="#fff"
                    backgroundColor="#007aff"
                    text="Upload RE"
                    onClick={() => setEyeToUpload("right")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </HcLayout>
  );
}

export default HcPatientDetails;
