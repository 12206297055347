import { useEffect, useContext } from "react";
import { AppContext } from "../../AppContext";
import { Outlet } from "react-router-dom";
import Dashboardlayout from "../../components/layout";
import HcLayout from "../../components/hcLayout";
import useUpdateChats from "../../hooks/chats";
import "./chats.css";

function Chats() {
  const { conversations, user } = useContext(AppContext);
  const { updateConversations } = useUpdateChats();

  // get conversations
  useEffect(() => {
    if (conversations.loading) {
      updateConversations();
    }

    // eslint-disable-next-line
  }, []);

  // create a useEffect to get all conversation messages

  // create a useEffect to use socket(listen) for(to) all conversations

  return user.role === "user" ? (
    <HcLayout>
      <Outlet />
    </HcLayout>
  ) : (
    <Dashboardlayout>
      <Outlet />
    </Dashboardlayout>
  );
}

export default Chats;
