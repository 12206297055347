import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import "../layout/panelHeader/PanelHeader.css";

function PanelHeader({ page, subpage, onClickMenu }) {
  const getDisplay = () => {
    if (page === "patients" && subpage === "add") {
      return "addPatient";
    }

    if (page === "appointments" && subpage === "add") {
      return "createAppointment";
    }

    return page;
  };
  const pageTitle = {
    dashboard: "Dashboard",
    patients: "Patients",
    addPatient: "Add Patient",
    appointments: "Appointments",
    createAppointment: "Create Appointment",
  };

  return (
    <div className="PanelHeader">
      <div className="panel-header-left">
        <Menu onClick={onClickMenu} />
        <h3>{pageTitle[getDisplay()] || "Not Found"}</h3>
      </div>
      <div className="panel-header-right" />
    </div>
  );
}

export default PanelHeader;
