import { useState, useContext, useEffect } from "react";
import AgoraUIKit, { layout } from "agora-react-uikit";
import ChatBoxHeader from "./ChatBoxHeader";
import Messages from "./Messages";
import Diagnosis from "./Diagnosis";
import PatientProfile from "./PatientProfile";
import { AppContext } from "../../../AppContext";

// hooks
import useUpdateChats from "../../../hooks/chats";

function ChatBox({
  conversationId,
  participantId,
  messageableId,
  participantType,
}) {
  const [chatPage, setChatPage] = useState("messages");
  const [patientId, setPatientId] = useState(0);
  const [inCall, setInCall] = useState(false);
  const [callConfig, setCallConfig] = useState({});
  const { messages, diagnoses, conversations, user } = useContext(AppContext);
  const { updateConversationMessages, updateDiagnoses } = useUpdateChats();

  useEffect(() => {
    if (!messages[conversationId]) {
      updateConversationMessages({
        conversationId,
        participantId,
        participantType,
      });
    }

    // eslint-disable-next-line
  }, [conversationId]);

  useEffect(() => {
    // get conversation
    const foundConversation = conversations.data.find(
      (c) => c.conversation.id === Number(conversationId)
    );

    // get patient id
    const foundPatientId =
      foundConversation.conversation.other_participants.find(
        (p) => p.messageable.height
      )?.messageable.uuid;
    setPatientId(foundPatientId);

    if (!diagnoses[foundPatientId]) {
      updateDiagnoses({
        foundPatientId,
      });
    }
  }, [conversations.data, conversationId]);

  return (
    <div className="chat-box">
      {inCall ? (
        <div className="video-call" style={{ display: "flex", width: "100%" }}>
          <AgoraUIKit
            rtcProps={{
              appId: callConfig.app_id,
              channel: callConfig.channelName,
              token: callConfig.token,
              uid: user.id,
              username: user.name,
              layout: layout.grid,
            }}
            callbacks={{
              EndCall: () => setInCall(false),
            }}
          />
        </div>
      ) : (
        <>
          <ChatBoxHeader
            conversationId={conversationId}
            messageableId={messageableId}
            chatPage={chatPage}
            setChatPage={setChatPage}
            setInCall={setInCall}
            setCallConfig={setCallConfig}
          />
          {chatPage === "messages" && (
            <Messages
              conversationId={conversationId}
              participantId={participantId}
              participantType={participantType}
            />
          )}
          {chatPage === "diagnosis" && <Diagnosis patientId={patientId} />}
          {chatPage === "profile" && (
            <PatientProfile setChatPage={setChatPage} uuid={patientId} />
          )}
        </>
      )}
    </div>
  );
}

export default ChatBox;
